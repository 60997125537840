@import './mixins';
:root{
    --color-dark: #2a2a2a;
    --color-yellow: #ffca2f;
    --body-bg: #fff;
    --text-color: #000;
    --link-color: #0e76a8;
    --border-color: #2a2a2a;

    // Dark mode 
    --dark-bg: #2a2a2a;
    --dark-text-color: #fff;
    --dark-link-color: #ffca2f;
    --dark-border-color: #fff;
}
$font-stack: 'Montserrat', sans-serif;

*{
    margin: 0;
    box-sizing: border-box;
    // font-family: $font-stack;
    scroll-behavior: smooth;
}

body{
    line-height: 1.5;
}

a{
    text-decoration: none;
    color: inherit;
}

.flex{
    display: flex;
    gap: 1rem;
}

.grid{
    display: grid;
    gap: 1rem;
}

.title{
    font-size: 2rem;
    font-weight: bolder;
}

ul{
    list-style: none;
}

.wrapper{
    background: var(--body-bg);
    min-height: 100vh;
    color: var(--text-color);
}

//apply dark mode
.dark-mode-checkbox:checked + .wrapper{
    --body-bg: var(--dark-bg);
    --text-color: var(--dark-text-color);
    --link-color: var(--dark-link-color);
    --border-color: var(--dark-border-color);
}

//Dark mode
#darkmode{
    display: none;
    position: fixed;
    top: 6rem;
    left: 1rem;
}

.dark-mode-toggler{
    position: fixed;
    top: 8rem;
    left: 2rem;
    cursor: pointer;

    &:hover{
        transform: scale(1.5);
        transition: all 0.5s;
    }
}

// =======header
.primary-header{
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: var(--body-bg);
    color: var(--text-color);
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.219);
    position: sticky;
    top:0;
    z-index: 99;

    .logo{
        justify-content: center;
        align-items: center;
        gap: 3rem;
        position: relative;

        div{
            &:nth-child(1){
                font-size: 1.8rem;
            }
            &:nth-child(2){
                font-size: 1rem;
                font-weight: bold;
            }
        }

        .line{
            &::before{
                content: "";
                background: var(--color-yellow);
                height: 60%;
                width: 5px;
                left: 45%;
                position: absolute;
            }
        }
    }

    .right{
        #check, .menu-icon{
            display: none;
        }
        .navigation {
            padding: 0;
            margin:0;
            gap: 2.5rem;

            li{
                a{
                    &:hover{
                        color: var(--color-yellow);
                        transition: all .5s;
                    }
                }
            }
        }
    }
}

// media query for tablet designs for header

@include tablet(){
   .primary-header{
    .right{
        .menu-icon{
        display: block;
        }

        
        .navigation{
            flex-direction: column;
            width: 15rem;
            height: 100vh;
            background: var(--body-bg);
            position: fixed;
            top: 4.7rem;
            left: 100%;
            transition: 0.5s ease-in;
            box-shadow: 0 0 0 1px solid rgba(128, 128, 128, 0.153);
           
            li{
                padding-left: 1rem;
            }


            
        }
        #check:checked ~ ul{
            left: calc(100% - 15rem);
        }
    }
}
}

// section1 home or hero section
.section1 {
    padding-top: 12rem;
    .hero{
    background: var(--color-yellow);
    margin: 0 auto 5rem auto;
    grid-template-areas: "left right";
    padding: 0rem 2rem;
    width: 90vw;
    .left{
        grid-area: left;
        margin-top: -4rem;
        justify-content: center;
        align-items: center;

        img{
            height: 115%;
        }
    }
    .right{
        grid-area: right;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.8rem;

        div{
            flex-direction: column;
            gap: 0.6rem;

            .name{
                font-size: 4rem;
                font-weight: bold;
                color: white;
            }

            .tag{
                color: white;
                font-size: 2.2rem;
            }
        }
        button{
            border: none;
            padding: 0.8rem 1.2rem;
            border-radius: 2rem;
            cursor: pointer;
            background: var(--color-dark);
            color: white;
            margin: 1rem 0;

            a{
            i{
                color: var(--color-yellow);
            }
        }
        }
    }
}
}

//tablet mode 
@include tablet(){
   .section1{
    .hero{
        grid-template-areas: 'left'
        'right';

        .right{
            align-items: center;
        }
    }
   }
}

// section2 banner 

.section2{
    .info-container{
        max-width: 80%;
        margin: auto;
        padding: 2rem;
        min-height: 7rem;
        border:  2px solid var(--border-color);
        border-radius: 100px;

        justify-content: center;
        align-items: center;

        .info-lists{
            width: 100%;
            color: var(--text-color);

            justify-content: space-around;
            align-items: center;

            .info-content{
                .icon-container{
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    background: var(--color-yellow);
                    color: #fff;

                    justify-content: center;
                    align-items: center;

                    i{
                        font-size: 1.4rem;
                    }
                }
                span{
                    h5{
                        font-weight: bolder;
                        letter-spacing: 2px;
                    }
                    p{
                        color: var(--color-yellow);
                    }
                }
            }

            .info-divider{
                width: 2px;
                height: 35px;
                background: grey;
            }
        }
    }
}

// section2 banner in tablet
@include tablet(){
    .section2{
        .info-container{
            .info-lists{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .info-content{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }


                .info-divider{
                    display: none;
                }
            }
        }
    }
}

// section3 skills
.section3{
    width: 80%;
    margin: 0 auto;
    padding-top: 10rem;
    .skills-container{
        justify-content: center;
        align-items: center;
        gap: 8rem;

        div{
            flex-direction: column;
            align-items: center;
            font-weight: bolder;

            i{
                font-size: 3rem;
            }
        }
    }
}

//section3 skills section in tablet
@include tablet(){
    .section3{
        .skills-container{
            gap: 2rem;
        }
    }
}

//section4 projects
.section4{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;

    .projects-container{
        width: 100%;
        padding: 1rem 2rem 0 2rem;

        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 2.5rem;

        .project-card{
            flex-direction: column;
            box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.31);
            border-radius: 10px;
            .top{
                img{
                    width: 100%;
                    height: 250px;
                    border-radius: 10px 10px 0 0;
                }
            }
            .bottom{
                padding: 2rem;

                p{
                    &:nth-child(1){
                        font-weight: bolder;
                    }
                }
            }
        }
    }
}

// section5 about me
.section5{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 4rem;
    padding-top: 7rem;


    .about-container{
        padding: 1rem 3rem;

        .left{
            width: 50%;
            padding: 0 2rem;

            img{
                width: 100%;
                height: 65vh;
            }
        }

        .right{
            flex-direction: column;
            width: 50%;
            gap: 2rem;
            padding: 0 2rem;

            h2{
                font-size: 3.5rem;
            }

            p{
               &:nth-child(3){
                font-weight: 600;
               } 
            }

            div{
                flex-direction: column;
                .tag{
                    font-weight: 600;
                }
                div{
                    flex-direction: row;
                    gap: 2rem;

                    span{
                        padding: 0.5rem 0.7rem;
                        &:hover{
                            background: rgba(128, 128, 128, 0.709);
                            border-radius: 1rem;
                            cursor: pointer;
                            color: white;
                            box-shadow: 0 0 10px grey;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

// section5 about me in tablet
@include tablet(){
    .section5{
        padding-top: 8rem;
        .about-container{
            flex-direction: column;
            .left{
                width: 100%;
                padding: 0 2rem;
    
                img{
                    width: 100%;
                }
            }

            .right{
                width: 100%;
            }
        }
    }
}
 
//section6 contact
.section6{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;

    .socials{
        padding: 4rem;
        width: 100%;
        justify-content: space-around;

        a{
                font-size: 4rem;
                &:nth-child(1){
                    color: blue;
                }
                &:nth-child(2){
                    color: #6e5494;
                }
                &:nth-child(3){
                    color: skyblue;
                }
                &:nth-child(4){
                    color: red;
                }
        }
    }

    .email-section{
        background-color: var(--border-color);
        padding: 1rem 2rem;
        border-radius: 100px;
        color: var(--body-bg);
        .email{
            justify-content: center;
            align-items: center;
           .send{
            width: 3rem;
            height: 3rem;
            background: var(--color-yellow);
            border-radius: 50%;
            
            display: flex;
            justify-content: center;
            align-items: center;
            }
        }
        
    }
}

// Scroll to top button
.to-top{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: var(--color-yellow);

    position: fixed;
    bottom: 20px;
    right: 20px;

    justify-content: center;
    align-items: center;
    div{
        i{
            color:white;
        }
    }
}

//footer
footer{
    background: #000;
    color: white;
    padding: 4rem;
    margin-top: 2rem;

    .footer-container{
        justify-content: space-around;
        margin-bottom: 4rem;
        .links, .socials{
            flex-direction: column;
            h3{
                text-transform: uppercase;
            }
            ul{
                flex-direction: column;
                padding: 0;

                li{
                    a{
                        &:hover{
                            color: var(--color-yellow);
                            transition: all .5s;
                        }
                    }
                }
            }
        }
        

    }

    .footer-bottom{
        text-align: center;
    }
}

//footer in tablet 
@include tablet(){
    footer{
        .footer-container{
            flex-direction: column;

            align-items: center;
            margin-bottom: 4rem;
        }
    
       
    }
}